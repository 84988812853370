// Fonts
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Plugins
@import 'line-awesome';
@import 'flaticon';
@import 'themify-icons';
@import 'owl.carousel';
@import 'magnific-popup';
@import 'scrollbar';
@import 'css';

// Theme
@import 'style';
@import 'skin';
@import 'template';
@import 'revolution.settings';
@import 'revolution.navigation';
