/* 
	Template Name: YogaZone: Yoga, Fitness & Maditation Mobile Responsive Bootstrap Html Template 
	Version: 1.0
	Author: DexignZone
	Website: http://www.dexignzone.com/
	Contact: dexignexpert@gmail.com
	Follow: www.twitter.com/dexignzones
	Like: www.facebook.com/dexignzone
	License: You must have a valid license purchased only from themeforest(the above link) in order to legally use the theme for your project.
*/

/*==========================
	Custom css
==========================*/

.h1 {
	font-size: 80px;
	font-weight: 700;
}

.h2 {
	font-size: 40px;
	line-height: 50px;
}

.h3 {
	font-size: 35px;
}

.h4 {
	font-size: 30px;
}

.h5 {
	font-size: 28px;
	font-weight: 100;
}

.h6 {
	font-size: 24px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: #193037;
	font-family: "Poppins", sans-serif;
}

.bg-img-fix {
	background-size: cover;
}

/*==== Home Page Css =====*/
hr {
	width: 100%;
}

.worker {
	margin-top: -30px;
}

.button-white .erinyen.tparrows {
	background-color: rgba(255, 255, 255, 0.5);
	color: #000;
}

.button-white .erinyen.tparrows:before {
	color: #000;
}

/* Our Gallery */
.our-gallery .dez-info-has {
	top: 0;
}

.our-gallery .media-box {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.gallery-block .filters li {
	list-style: none;
	display: inline-block;
}

.gallery-block .filters li a {
	font-size: 15px;
	font-weight: 700;
	padding: 5px 15px;
	text-transform: uppercase;
}

.gallery-block .dez-box {
	margin-bottom: 0;
}

.gallery-block .section-head {
	margin-bottom: 20px;
}

/* Our Schedule */
.our-schedule .ow-entry-content {
	padding: 15px;
}

.our-schedule .dez-social-icon li a {
	border: 0;
	background-color: rgba(0, 0, 0, 0.2);
	color: #fff;
	width: 35px;
	height: 35px;
	padding: 0;
	line-height: 35px;
	font-size: 15px;
}

.our-schedule .dez-box-team {
	margin-top: -30px;
	position: relative;
	z-index: 1;
}

.our-schedule .dez-box-team .dez-title a:hover {
	color: #fff;
}

/* Company Stats */
.company-stats {
	position: relative;
	z-index: 1;
}

/* Owl Nav */

.owl-nav-none .owl-nav {
	display: none;
}

.owl-nav-none .owl-carousel {
	cursor: grab;
}

/*==== Pricing Table =====*/

.yoga-pricing .pricingtable-wrapper {
	background-color: rgba(255, 255, 255, 0.2);
}

.yoga-pricing .pricingtable-highlight {
	margin-bottom: 0;
}

.yoga-pricing .pricingtable-features {
	border: 0;
}

.yoga-pricing .pricingtable-footer,
.yoga-pricing .pricingtable-features li:nth-child(2n),
.yoga-pricing .pricingtable-price {
	background-color: rgba(255, 255, 255, 0.05);
	border-width: 0;
	color: #fff;
}

.yoga-pricing .pricingtable-features li {
	border-width: 0;
}

.yoga-pricing .pricingtable-title {
	background-color: rgba(0, 0, 0, 0);
}

.yoga-pricing .pricingtable-bx,
.yoga-pricing .pricingtable-features li {
	color: #fff;
}

.bg-secondry-dark {
	background-color: #2D3239;
}

.price-table .pricingtable-price,
.price-table .pricingtable-footer {
	border: 0;
	position: relative;
}

.price-table .pricingtable-features,
.price-table .pricingtable-features li {
	border: 0
}

.price-table .pricingtable-price-bx h2 {
	font-size: 60px;
	font-weight: bold;
	color: #2D3239;
	line-height: 65px;
}

.price-table .pricingtable-price-bx span {
	font-size: 20px;
	font-weight: bold;
	color: #2D3239;
}

.price-table .pricingtable-price.text-white:after,
.price-table .pricingtable-price.text-white:before {
	background-color: inherit;
	bottom: -10px;
	content: "";
	height: 30px;
	position: absolute;
	width: 50%;
	z-index: 2;
}

.price-table .pricingtable-price.text-white:after {
	-moz-transform: skewY(7deg);
	-webkit-transform: skewY(7deg);
	-o-transform: skewY(7deg);
	-ms-transform: skewY(7deg);
	transform: skewY(7deg);
	left: 0;
}

.price-table .pricingtable-price.text-white:before {
	-moz-transform: skewY(-7deg);
	-webkit-transform: skewY(-7deg);
	-o-transform: skewY(-7deg);
	-ms-transform: skewY(-7deg);
	transform: skewY(-7deg);
	left: auto;
	right: 0;
}

/*==== Pricing Table End =====*/

/* Home 2 */
.our-projects-galery .dez-gallery-box {
	margin-bottom: 0;
}

/* Home 6 */
.yoga-woman img {
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

.benefits-yoga .dez-box.left {
	margin-right: -40px;
}

.benefits-yoga .dez-box.right {
	margin-left: -40px;
}

/*==== Appointment Form Css =====*/
/* Mack An Appointment Dark */
.mack-an-appointment {
	background-color: #1d2229;
}

.mack-an-appointment {}

.mack-an-appointment [class*="col-md-"] {
	padding: 0 7.5px
}

.mack-an-appointment .form-group {
	margin-bottom: 15px;
}

.mack-an-appointment .form-control {
	background-color: #181d24;
	border: 1px solid #292929;
}

.mack-an-appointment .bootstrap-select button.dropdown-toggle {
	background-color: #181d24 !important;
	border: 1px solid #292929 !important;
}

/* Mack An Appointment Dark End */

/* Mack An Appointment White */
.mack-an-appointment.white {
	background-color: #fff;
	border: 1px solid #d4d4d4;
}

.mack-an-appointment.white .form-control {
	background-color: #fff;
	border: 1px solid #d4d4d4;
}

.mack-an-appointment.white .bootstrap-select button.dropdown-toggle {
	background-color: #fff !important;
	border: 1px solid #d4d4d4 !important;
}

/* Mack An Appointment White End */
/*==== Appointment Form Css End ====*/

/* Testimoniyal Home 5 */
.client-pic li {
	position: relative;
	list-style: none;
	display: inline-block;
}

.client-pic li a {
	border: 2px solid;
	border-radius: 100%;
	display: block;
	height: 80px;
	overflow: hidden;
	margin: 0 2px;
	width: 80px;
}

.client-think {
	position: relative;
	max-width: 780px;
	margin: 0px auto;
	background-color: #E8E8E7;
	border-radius: 30px;
}

.client-think:before {
	content: "\f10e";
	font-family: 'Font Awesome 5 Free';
	font-weight: 600;
	bottom: 0;
	color: rgba(0, 0, 0, 0.1);
	font-size: 80px;
	position: absolute;
	right: 30px;
}

.client-pic li .active:after {
	content: "\f0d7";
	font-family: 'Font Awesome 5 Free';
	font-weight: 600;
	color: #e8e8e7;
	display: block;
	font-size: 60px;
	left: 50%;
	margin-left: -15px;
	overflow: hidden;
	position: absolute;
	top: -80px;
}

/* White */
.client-think-white {
	border-radius: 30px;
	margin: 0 auto;
	max-width: 780px;
	position: relative;
}

.client-think-white:before {
	bottom: 0;
	color: rgba(0, 0, 0, 0.1);
	content: \f10e;
	font-family: "FontAwesome";
	font-size: 80px;
	position: absolute;
	right: 30px;
}

/* Home 10 */
ul.u-list {
	margin: 0;
	padding: 0;
}

ul.u-list li {
	list-style: none;
}

/* Home 10 End */
/*==== Home Page Css End =====*/
/*==== Responsive Css =====*/

@media only screen and (max-width: 1024px) {
	.our-gallery>.col-md-6 {
		width: 100%;
	}

	.our-gallery>.col-md-3 {
		width: 50%;
	}

	h2 {
		font-size: 20px
	}
}

@media only screen and (max-width: 991px) {
	body .dzseth>div.col-md-4 {
		height: auto !important;
	}
}

@media only screen and (max-width: 767px) {

	.our-gallery .dez-media img,
	.our-gallery .dez-post-media img {
		min-height: 320px;
		object-fit: cover;
		width: 100%;
	}

	.h2 {
		font-size: 24px;
	}

	.footer-info-box .newsletter .input-group {
		margin-bottom: 10px;
	}

	.our-gallery>.col-md-6 {
		width: auto;
	}

	.our-gallery>.col-md-3 {
		width: auto;
	}

	.benefits-yoga .dez-box.left {
		margin-right: 0;
	}

	.benefits-yoga .dez-box.right {
		margin-left: 0;
	}

	.yoga-woman img {
		transform: scale(1);
		-moz-transform: scale(1);
		-webkit-transform: scale(1);
		-o-transform: scale(1);
		-ms-transform: scale(1);
	}
}

/*======== Spacing ==========*/
/* spacing */
.site-footer .widget_getintuch {
	padding-top: 0;
}

.content-inner {
	padding-top: 70px;
	padding-bottom: 40px;
}

.content-inner-1 {
	padding-top: 70px;
	padding-bottom: 70px;
}

.content-inner-2 {
	padding-top: 70px;
}

.testimonial-five .owl-controls {
	margin-top: 0px;
}

.blog-page-content .dez-gallery-box {
	margin-bottom: 20px;
}

.dez-newsletter.style1 {
	background-color: rgba(255, 255, 255, 0.05)
}

.inner-haed {
	margin-bottom: 25px;
}

.awesome-services .m-r50 {
	margin-right: 0;
}

.awesome-services .m-l50 {
	margin-left: 0;
}

.side-bar .widget:last-child {
	margin-bottom: 0;
}

/* Mobile Spacing */
@media only screen and (max-width: 991px) {
	.disnone-sm {
		display: none;
	}

	.over-50[class*="overlay-"]:after {
		width: 100%;
	}

	.awesome-services .m-r50 {
		margin-right: 50px;
	}

	.awesome-services .m-l50 {
		margin-left: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.content-area {
		padding-top: 30px;
	}

	.disnone-md {
		display: none;
	}

	.page-content {
		padding-bottom: 30px;
	}

	.footer-top {
		padding-top: 30px;
	}

	.site-footer .widget {
		margin-bottom: 25px;
	}

	.section-full:last-child {
		margin-bottom: -30px;
	}

	.content-inner {
		padding-bottom: 20px;
		padding-top: 30px;
	}

	.section-head {
		margin-bottom: 25px;
	}

	.w3-project-left {
		padding-top: 30px;
	}

	.testimonial-section {
		padding-top: 30px;
	}

	.testimonial-section .owl-controls {
		margin-top: 20px;
	}

	.section-head p {
		padding-top: 0;
	}

	.h3 {
		font-size: 28px;
		margin-top: 10px;
	}

	.about-service .icon-bx-wraper:nth-child(2) {
		margin-top: 0;
	}

	.side-bar {
		margin-bottom: 30px;
	}

	.w3-post-title .post-title {
		margin-top: 10px;
	}

	.footer-info-box .newsletter {
		margin-bottom: 20px;
	}

	ol.commentlist li .star-rating {
		position: unset;
	}

	.comment-form [class*="comment-form"] {
		width: 100%;
	}

	.pricingtable-wrapper {
		margin-bottom: 30px;
	}

	.pricingtable-highlight {
		margin: 0;
	}

	.title-head {
		display: none;
	}

	.disnone-md {
		display: none;
	}

	.h2 {
		font-size: 30px;
	}

	.content-inner-1 {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.content-inner-2 {
		padding-top: 30px;
	}

	.mack-an-appointment [class*="col-md-"] {
		padding: 0 15px;
	}

	.mack-an-appointment.mack-an1 .row.clearfix {
		margin: 0 -15px;
	}

	.service-box {
		margin-top: 0;
	}

	.main-slider .erinyen.tparrows {
		min-height: 40px;
		min-width: 40px;
	}

	.main-slider .erinyen.tparrows:before {
		font-size: 14px;
		line-height: 40px;
	}

	.main-slider .erinyen .tp-title-wrap {
		line-height: 40px;
		min-height: 40px;
	}

	.main-slider .erinyen .tp-arr-titleholder {
		padding: 0 10px;
		line-height: 40px;
	}

	.dez-tabs.vertical .nav-tabs {
		width: 40px;
	}

	.dez-tabs.vertical .tab-content {
		margin-left: 39px;
	}

	.dez-tabs.vertical.right .tab-content {
		margin-right: 39px;
	}

	.dez-full-blog .dez-post-info,
	.full-blog-dark .dez-full-blog .dez-post-info {
		padding-bottom: 0;
	}

	.button-page button {
		margin-bottom: 10px;
	}

	.dez-accordion .collapsed {
		font-size: 14px;
	}

	.acod-head a:after {
		font-size: 12px;
	}

	.mfp-gallery {
		margin-bottom: 0;
	}
}

/*===== Responsive Css End =====*/
.dez-img-overlay1.gradient:before {
	outline: 1px dashed rgba(255, 255, 255, 0.4);
	outline-offset: -10px;
	content: "\f103";
	font-family: Flaticon;
	color: #fff;
	font-size: 20px;
	padding: 48%;
}

.dez-img-overlay1.gradient:hover:before,
.dez-box:hover .dez-img-overlay1.gradient:before {

	opacity: 0.9;
}

.yoga-woman {
	position: relative;
	z-index: 1;
}

.box-zoom {
	animation: 1.3s ease-out 75ms normal none 1 running fusionSonarEffect;
	width: 30px;
	height: 30px;
	border-radius: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -15px;
	animation-iteration-count: infinite;
	z-index: -1;
	opacity: 0.8;
}

@keyframes fusionSonarEffect {
	0% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.5;
	}

	100% {
		opacity: 0;
		transform: scale(1.5);
	}
}

@keyframes fusionSonarEffect {
	0% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.5;
	}

	100% {
		opacity: 0;
		transform: scale(15);
	}
}

.dis-tbl {
	display: table;
}

.dis-tbl-cell {
	display: table-cell;
	vertical-align: middle;
}

.img-cover {
	object-fit: cover;
	width: 100%;
}

.testimonial-six .owl-prev:hover,
.testimonial-six .owl-next:hover {
	opacity: 0.9;
}

.testimonial-six .owl-prev,
.testimonial-six .owl-next {
	background-color: rgba(0, 0, 0, 0);
	border: 0 none;
	height: auto;
	padding: 0;
	width: auto;
	font-size: 40px;
	opacity: 0.4;
	font-weight: 100;
}

.pricingtable-inner {
	outline: 1px dashed rgba(0, 0, 0, 0.5);
	outline-offset: -1px;
}

/* Counter */
.counter-style-1 .counter {
	font-size: 50px;
	font-weight: 600;
}

.counter-style-1 .counter-text {
	font-size: 16px;
	font-weight: 600;
}

.counter-style-1 .icon {
	font-size: 45px;
	margin-right: 10px;
}


/*================================================*/
.fc-button.fc-state-default {

	border: 0 solid;
	border-radius: 0;
	color: #ffffff;
	height: auto;
	margin: 0 1px;
	padding: 10px 15px;
	text-shadow: unset;
}

.fc-state-default {
	background-image: unset;
}

.fc-button.fc-state-default:hover,
.fc-button.fc-state-default:hover,
.fc-button.fc-state-down,
.fc-button.fc-state-active {
	box-shadow: none;

}

.fc-day-header.fc-widget-header {
	border-color: rgba(255, 255, 255, 0.2);
	color: #ffffff;
	padding: 14px;
}

.fc-row table {
	margin-bottom: 0 !important;
}

.fc-widget-content .fc-event {
	border: 0;
	border-radius: 0;
	padding: 5px 10px;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
	border-color: rgba(0, 0, 0, 0.1);
}

/* Contact Us */
.contact-style-1 input {
	height: 50px;
}

.contact-style-1 .site-button {
	height: 50px;
	width: 100%;
}

.contact-style-1 .form-control {
	padding: 10px 20px;
}

.contact-style-1 .form-control:focus,
.contact-style-1 .form-control:hover {
	border-color: #c0c0c0;
}

.contact-style-1 h2 {
	margin-top: 0;
}

.contact-style-1 .border-1 {
	border: 1px solid #eee;
}

.contact-style-1 .social-icon-lg {
	border-top: 1px solid #eeeeee;
	padding-top: 20px;
	text-align: left;
	width: 100%;
	margin: 0;
}

.contact-style-1 .dez-social-icon a {
	text-align: center;
}

.contact-style-1 .dez-social-icon li a:hover {
	background: #fff;
	color: #000;
	border: 1px solid #eee;
}

.contact-style-1 .form-group {
	margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
	.fc-button.fc-state-default {
		padding: 5px;
	}

	.box-zoom {
		display: none;
	}
}

.breadcrumb-row ul li {
	display: inline;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	z-index: 2;
	color: #fff;
}

textarea.form-control {
	height: auto;
}

/* tab */
.dez-tabs.border {
	border: 0;
}

.dez-tabs .nav-tabs>li>a.active:hover {
	background-color: #fff;
	border-color: #ddd;
	border-bottom: 1px solid #FFF;
}

.nav-tabs .nav-link.active,
.dez-tabs.bg-tabs .nav-tabs>li>a.active {
	border-bottom: 1px solid transparent;
	background-color: #fff;
}

.dez-tabs.border-tp .nav-tabs>li>a.active {
	border-top: 2px solid #EFBB20;
}

.dez-tabs.vertical .nav-tabs li a.active {
	border-bottom: 1px solid #ddd;
}

.dez-tabs.vertical .nav-tabs li {
	width: 100%;
	margin-right: -1px;
}

.dez-tabs.vertical .nav-tabs li a.active {
	margin-right: -1px;
	border-right: none;
}

.dez-tabs.vertical .nav-tabs li a {
	margin-right: -1px;
}

.dez-tabs.vertical.right .nav-tabs li a.active {
	border-right: 1px solid #ddd;
	border-left: none;
}

.site-filters .site-button.active {
	background-color: #ff91b8;
}

.site-filters .site-button.active {
	color: #fff;
}

/* sidenav */
.sidenav .bg-primary {
	color: #fff;
}

.yoga-footer .newsletter input[type="email"] {
	background-color: #6F3662;
	border: 0;
	border-radius: 30px !important;
}

.yoga-footer .newsletter input[type="email"]::placeholder {
	color: rgba(255, 255, 255, .7);
}

.bg-primary {
	color: #fff;
}

.section-head h1,
.section-head h2,
.section-head h3 {
	font-weight: 600;
}

.dez-post-title .post-title {
	font-size: 21px;
	font-weight: 600;
	margin-bottom: 10px;
	margin-top: 20px;
}

.dez-tilte {
	font-weight: 600;
}

.share-details-btn ul li {
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 6px;
}

.site-button.facebook {
	background-color: #3B5998;
}

.site-button.google-plus {
	background-color: #DE4E43;
}

.site-button.linkedin {
	background-color: #007BB6;
}

.site-button.instagram {
	background-color: #8A5A4E;
}

.site-button.twitter {
	background-color: #1EA1F3;
}

.site-button.whatsapp {
	background-color: #01C854;
}

.counter-box h5 {
	font-size: 13px;
	line-height: 22px;
}

/* footer */
footer li {
	font-size: 14px;
}

.widget .post-title {
	font-weight: 600;
}

.testimonial-1 {
	width: 70%;
	margin: auto;
}

@media only screen and (max-width: 1024px) {
	.style-3 .dez-full-blog {
		width: auto;
	}

	.styleswitcher {
		display: none;
	}
}

@media only screen and (max-width: 991px) {

	/* footer */
	.footer-bottom .text-right,
	.footer-bottom .text-left {
		text-align: center !important;
	}

	.is-fixed .header-nav .nav {
		height: auto;
	}
}

@media only screen and (max-width: 768px) {
	.footer-fixed .site-footer {
		position: relative;
	}

	.footer-fixed .page-wraper {
		padding-bottom: 0 !important;
	}

	.blog-post.blog-md.date-style-2 .dez-post-media {
		width: 100%;
		margin-bottom: 15px;
	}

	ol.comment-list li .children {
		margin-left: 0;
	}

	.comments-area .reply .comment-reply-link {
		position: unset !important;
	}

	.comments-area .comment-form p {
		width: 100%;
	}

	.blog-gallery .blog-post {
		margin-bottom: 0;
	}

	.testimonial-1 {
		width: 100%;
	}
}

.site-footer.footer-white .footer-bottom a::after {
	background-color: #000;
}

.site-footer .footer-bottom a::after {
	background-color: #fff;
}

.site-footer.footer-white .footer-bottom a::after,
.site-footer .footer-bottom a::after {
	height: 3px;
	width: 3px;
	content: "";
	position: absolute;
	border-radius: 50px;
	top: 11px;
	right: -9px;
}

.footer-bottom a:last-child:after {
	position: relative !important;
}

.footer-bottom a {
	margin-right: 10px;
	position: relative;
}

.blog-carousel .post-title {
	margin: 0;
}

.dez-bnr-inr-entry h1 {
	line-height: 55px;
}

.error-text {
	font-weight: bold;
}

.fc-day-grid-event.fc-event.fc-draggable:hover,
.fc-day-grid-event.fc-event.fc-draggable {
	color: #fff;
}

.login-form .tab-content.nav {
	display: unset;
}

.login-form .form-group label input {
	display: none;
}

/* Home Page 4 */
.section-head-2 {
	margin-bottom: 40px;
}

.section-head-2.text-white .title {
	color: #fff;
}

.section-head-2.text-white p {
	color: #fff;
	opacity: 0.8;
}

.section-head-2 .title {
	font-family: 'Lora', serif;
	font-weight: 700;
	line-height: 70px;
	font-size: 50px;
	margin-top: 0;
	margin-bottom: 0;
	color: var(--color-primary);
}

.section-head-2 p {
	font-weight: 400;
	line-height: 30px;
	font-size: 18px;
	color: #000;
	padding-top: 5px;
	margin-bottom: 0;
}

.section-head-2.text-center p {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.service-bx1 {
	background: #fff;
	padding: 50px 40px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.service-bx1:hover {
	box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.08);
	transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}

.service-bx1 .dez-tilte {
	font-family: 'Lora', serif;
	font-weight: 700;
	font-size: 28px;
	line-height: 40px;
	margin-bottom: 10px;
}

.service-bx1 p {
	font-size: 16px;
	line-height: 32px;
	font-weight: 400;
}

.service-bx1 .icon-xl img {
	height: 100px;
	width: 100px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.service-bx1:hover .icon-xl img {
	transform: scale(1.2);
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
}

.timeline {
	position: relative;
}

.timeline:after {
	content: "";
	position: absolute;
	left: 50%;
	width: 2px;
	height: 100%;
	background: var(--color-primary);
	top: 0;
}

.timeline-bx {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.timeline-bx:nth-child(2n) {
	flex-direction: row-reverse;
}

.timeline-bx .media {
	background: #fff;
	padding: 30px 30px;
	margin-right: 30px;
}

.timeline-bx .info {
	margin-left: 30px;
	position: relative;
}

.timeline-bx:nth-child(2n) .media {
	margin-right: 0;
	margin-left: 30px;
}

.timeline-bx:nth-child(2n) .info {
	margin-left: 0;
	margin-right: 30px;
}

.timeline-bx .info:after {
	content: "";
	width: 20px;
	height: 20px;
	position: absolute;
	left: -39px;
	background: #fff;
	border-radius: 100%;
	top: 50%;
	z-index: 1;
	border: 3px solid var(--color-primary);
}

.timeline-bx:nth-child(2n) .info:after {
	left: auto;
	right: -41px;
}

.timeline-bx .info .title {
	font-family: 'Lora', serif;
	font-weight: 700;
	margin-top: 0;
	font-size: 30px;
	margin-bottom: 15px;
	color: #000;
}

.timeline-bx .info p {
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 0;
}

.timeline-bx .media,
.timeline-bx .info {
	flex: 0 0 calc(50% - 30px);
	max-width: calc(50% - 30px);
}

.yoga-form .form-control {
	background: transparent;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 6px !important;
	height: 60px;
	color: #fff;
	padding: 15px 25px;
	font-size: 16px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.yoga-form .form-control:focus,
.yoga-form .form-control:hover {
	background: transparent;
	border: 2px solid rgba(255, 255, 255, 0.8);
}

.yoga-form textarea.form-control {
	height: 150px;
}

.yoga-form .form-control::-moz-placeholder {
	color: #fff;
	opacity: 0.7;
}

.yoga-form .form-control:-moz-placeholder {
	color: #fff;
	opacity: 0.7;
}

.yoga-form .form-control:-ms-input-placeholder {
	color: #fff;
	opacity: 0.7;
}

.yoga-form .form-control::-webkit-input-placeholder {
	color: #fff;
	opacity: 0.7;
}

.footer-yoga .list-inline {
	margin-bottom: 0;
	margin-top: 0;
}

.footer-yoga .dez-social-icon li a {
	padding: 0;
	width: 42px;
	height: 42px;
	line-height: 42px;
	font-size: 16px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	border-radius: 42px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	line-height: 40px;
}

.footer-yoga .dez-social-icon li a:hover {
	background: #fff;
	color: #000;
}

.footer-yoga .list-inline li {
	padding: 0;
	display: inline-block;
}

.footer-yoga .list-inline li a {
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 0 10px;
	line-height: 24px;
}

.footer-yoga .list-inline li a:hover {
	text-decoration: underline;
	color: #fff;
}

.footer-yoga .footer-top {
	background: #2e4553;
	padding: 50px 0 50px;
}

.footer-yoga p {
	margin: 0;
	font-size: 15px;
}

.footer-yoga p a {
	color: #fff;
	opacity: 0.5;
}

.footer-yoga .widget {
	margin-bottom: 25px;
}

@media only screen and (max-width: 991px) {
	.timeline:after {
		left: 0;
	}

	.timeline-bx:nth-child(2n) {
		flex-direction: row;
	}

	.timeline-bx:nth-child(2n) .media {
		margin-right: 0;
		margin-left: 0;
	}

	.timeline-bx .media,
	.timeline-bx .info {
		flex: 0 0 calc(50% - 0px);
		max-width: calc(50% - 0px);
	}

	.timeline-bx .info {
		margin-left: 0px;
		padding-left: 20px;
	}

	.timeline-bx .media {
		margin-right: 0;
	}

	.timeline-bx:nth-child(2n) .info {
		margin-right: 0;
	}

	.timeline-bx .info:after,
	.timeline-bx:nth-child(2n) .info:after {
		left: calc(-100% - 29px);
		right: auto;
	}

	.timeline {
		padding-left: 20px;
	}

	.timeline-bx .media {
		padding: 15px 15px;
	}

	.timeline-bx .info .title {
		font-size: 24px;
		margin-bottom: 5px;
	}

	.timeline-bx .info p {
		font-size: 15px;
		line-height: 26px;
	}

}

@media only screen and (max-width: 767px) {
	.section-head-2 .title {
		line-height: 45px;
		font-size: 35px;
	}

	.timeline-bx .media,
	.timeline-bx .info {
		flex: 0 0 calc(100% - 0px);
		max-width: calc(100% - 0px);
	}

	.timeline-bx:nth-child(2n),
	.timeline-bx {
		flex-direction: column;
	}

	.timeline-bx .info {
		padding: 15px 0 0 0;
	}

	.timeline-bx .info:after,
	.timeline-bx:nth-child(2n) .info:after {
		left: -28px;
		right: auto;
	}

	.section-head-2 .title br {
		display: none;
	}

	.site-footer .footer-info-box strong {
		text-align: center !important;
		display: block;
	}

	.footer-info-box .text-right {
		text-align: center !important;
		margin-top: 15px;
	}

	.dez-social-icon li {
		margin-bottom: 10px;
	}

	.dez-accordion .acod-title {
		font-size: 14px;
	}

	.dez-topbar-right.list-unstyled {
		display: block;
	}

	.extra-nav {
		margin-right: 0;
	}

	.dez-topbar-right.list-unstyled li {
		padding-right: 0;
	}

	.dez-topbar-right.list-unstyled span {
		display: none;
	}
}

@media only screen and (max-width: 576px) {
	.error-text {
		font-size: 100px;
		line-height: 100px;
	}

	.page-content.dez-login {
		padding: 0 0 50px;
	}

	.rev-btn.rv4 .site-button {
		padding: 12px 20px;
		font-size: 13px;
		margin: 0 5px;
	}
}

.text-center p,
.testimonial-8 p,
.icon-bx-wraper.center p,
.testimonial-1 p,
.testimonial-3 p,
.icon-bx-wraper.right p,
.icon-bx-wraper.left p {
	text-align: unset;
}

p,
.dez-accordion .acod-content,
.nav-tabs .tab-content p {
	text-align: justify;
}

.tp-login-white .form-control::placeholder {
	color: rgba(255, 255, 255, .7);
}
