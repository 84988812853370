/*
	Template Name: YogaZone: Yoga, Fitness & Maditation Mobile Responsive Bootstrap Html Template
	Version: 1.0
	Author: DexignZone
	Website: http://www.dexignzone.com/
	Contact: dexignexpert@gmail.com
	Follow: www.twitter.com/dexignzones
	Like: www.facebook.com/dexignzone
	License: You must have a valid license purchased only from themeforest(the above link) in order to legally use the theme for your project.
*/

/*=====================
	Premery bg color
=====================*/
:root {
	--color-primary: #d9747c;
}

/* Hex */
.meet-ask-row:after,
.dez-separator.style-skew[class*="style-"]:after,
.dez-separator.style-skew[class*="style-"]:before,
.dez-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.pricingtable-title,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type="submit"],
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active>[class*="site-button"],
.list-num-count>li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
.pricingtable-title,
#myNavbar li.active a,
.nav-list li a:hover,
.closebtn,
.closebtn:hover,
.fc-day-header.fc-widget-header,
.fc-button.fc-state-default:hover,
.fc-button.fc-state-down,
.fc-button.fc-state-active,
.fc-button.fc-state-default,
.dropdown-item.active,
.dropdown-item:active {
	background-color: #d9747c;
}

.bg-primary,
.tp-bannertimer.bg-primary {
	background-color: #d9747c !important;
}

/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active>.site-button,
.bg-primary-dark,
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus,
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.footer-social li a:hover {
	background-color: #ff6fa2;
}

/* Rgba color */
.dez-info-has.bg-primary {
	background-color: rgba(255, 145, 184, 0.9);
}

/*=====================
	Premery text color
=====================*/
a,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a .header-nav .nav>li .sub-menu li a:hover,
.header-nav .nav>li:hover>a,
.header-nav .nav>li .mega-menu>li ul a:hover,
.header-nav .nav>li .sub-menu li:hover>a,
.nav-dark.header-nav .nav>li .sub-menu li:hover>a,
.nav-dark.header-nav .nav>li .mega-menu>li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dez-tabs .nav-tabs>li>a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.client-pic-white.client-pic li.active:after {
	color: #d9747c;
}

.breadcrumb-row ul li a {
    color: #FFFFFF !important;
}

.text-primary {
	color: #d9747c !important;
}

/*=====================
	Premery border color
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover {
	border-color: #d9747c;
}

/*=====================
	Secondery bg color
 =====================*/
.bg-secondry {
	background-color: #2d3239;
}

/*=====================
	Secondery text color
 =====================*/
.text-secondry {
	color: #2d3239;
}

/*=====================
		Garllery
 =====================*/
.our-gallery .dez-info-has {
	background-color: #d9747c;
	box-shadow: 0 0 0 15px #d9747c inset, 0 0 0 16px #ffffff inset;
}

.dez-img-overlay1.gradient:before {
	background: -moz-linear-gradient(45deg, #e63f75 0%, #c586d8 46%, #d9747c 82%);
	background: -webkit-linear-gradient(45deg, #e63f75 0%, #c586d8 46%, #d9747c 82%);
	background: linear-gradient(45deg, #e63f75 0%, #c586d8 46%, #d9747c 82%);
}

.bgprlight {
	background-color: #f4ecef;
}
