/* Flaticon icon font: Flaticon Creation date: 30/12/2017 13:45 */
@font-face {
	font-family: "Flaticon";
	src: url("/../fonts/Flaticon.eot");
	src: url("/../fonts/Flaticon-1.eot") format("embedded-opentype"),
	url("/../fonts/Flaticon.woff") format("woff"),
	url("/../fonts/Flaticon.ttf") format("truetype"),
	url("/../fonts/Flaticon.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("/../fonts/Flaticon.svg") format("svg");
	}
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-speaker:before {
	content: "\f100";
}

.flaticon-like-1:before {
	content: "\f101";
}

.flaticon-play-button:before {
	content: "\f102";
}

.flaticon-magnifying-glass:before {
	content: "\f103";
}

.flaticon-right-arrow:before {
	content: "\f104";
}

.flaticon-left-arrow:before {
	content: "\f105";
}

.flaticon-menu:before {
	content: "\f106";
}

.flaticon-downloading-from-smartphone:before {
	content: "\f107";
}

.flaticon-trophy:before {
	content: "\f108";
}

.flaticon-rocket-ship:before {
	content: "\f109";
}

.flaticon-technology:before {
	content: "\f10a";
}

.flaticon-calendar:before {
	content: "\f10b";
}

.flaticon-user:before {
	content: "\f10c";
}

.flaticon-users:before {
	content: "\f10d";
}

.flaticon-diamond:before {
	content: "\f10e";
}

.flaticon-internet:before {
	content: "\f10f";
}

.flaticon-chat:before {
	content: "\f110";
}

.flaticon-pencil:before {
	content: "\f111";
}

.flaticon-email:before {
	content: "\f112";
}

.flaticon-phone-call:before {
	content: "\f113";
}

.flaticon-like:before {
	content: "\f114";
}

.flaticon-apple:before {
	content: "\f115";
}

.flaticon-android:before {
	content: "\f116";
}

.flaticon-google-play:before {
	content: "\f117";
}

.flaticon-devices:before {
	content: "\f118";
}

.flaticon-smartphone:before {
	content: "\f119";
}

.flaticon-money:before {
	content: "\f11a";
}

.flaticon-team:before {
	content: "\f11b";
}

.flaticon-notebook:before {
	content: "\f11c";
}

.flaticon-bar-chart:before {
	content: "\f11d";
}

.flaticon-file:before {
	content: "\f11e";
}

.flaticon-inbox:before {
	content: "\f11f";
}

.flaticon-picasa:before {
	content: "\f120";
}

.flaticon-layers:before {
	content: "\f121";
}
